.vertical-buttons {
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.vertical-buttons a {
    width: 100%;
}

.svg-inline--fa{
    margin-right: 5px;
}